interface AccessRightsResponse {
  allParameters: Record<string, { available_values: string[]; default_value: string; type: string }>;
  userParameters: { parameter: string; value: string }[];
}

export interface AuthStore {
  isLogged: boolean;
  accessRights: Record<string, boolean> | null;
}

export const useAuthStore = createAuthStore({
  isLogged: false,
  accessRights: null,
});

export function createAuthStore(initialState: AuthStore) {
  return defineStore('auth', {
    state: () => initialState,

    actions: {
      async submitPhone(phone: string, token?: string) {
        try {
          const { data } = await useCustomFetch<{ message: string; status: string }>('/auth/login', {
            method: 'POST',
            body: { phone },
            query: { recaptchaToken: token },
          });

          if (data.value) {
            return data.value;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async validateCode(code: string) {
        try {
          const { data, error } = await useCustomFetch<{ message: string; status: string }>('/auth/validate-login', {
            method: 'POST',
            body: { code },
          });

          if (data.value) {
            this.isLogged = true;
            return data.value;
          }
          if (error.value?.data) {
            this.isLogged = false;
            return error.value.data;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async logout() {
        try {
          await useCustomFetch('/auth/logout', { method: 'POST' });

          this.isLogged = false;
          this.accessRights = null;
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async tgLogin(user: any) {
        try {
          const { data } = await useCustomFetch('/telegram/auth', {
            method: 'POST',
            body: user,
          });

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }

          return null;
        }
      },

      async vkLogin() {
        try {
          const { data } = await useCustomFetch<{ auth_url: string }>('/vk');

          if (data.value) {
            return data.value.auth_url;
          } else {
            return '';
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }

          return '';
        }
      },

      async yandexLogin() {
        try {
          const { data } = await useCustomFetch<{ auth_url: string }>('/yandex');

          if (data.value) {
            return data.value.auth_url;
          } else {
            return '';
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }

          return '';
        }
      },

      async getAccessRights() {
        try {
          const { data } = await useCustomFetch<{ data: AccessRightsResponse }>('/auth/access-rights');

          if (data.value && data.value.data && data.value.data.userParameters?.length) {
            Object.entries(data.value.data.allParameters).forEach(([key, value]) => {
              this.accessRights = {
                ...this.accessRights,
                [key]: Boolean(Number(value.default_value)),
              };
            });

            for (const { parameter, value } of data.value.data.userParameters) {
              if (this.accessRights && value === '1') this.accessRights[parameter] = true;
            }
          } else {
            this.accessRights = null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },
    },
  });
}
